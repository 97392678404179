import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { Stripe, StripeCardElement, StripeError } from '@stripe/stripe-js';
import { useState, useEffect } from 'react';
import { DeepMap, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { DevTool } from '@hookform/devtools';
import { isDevelopment } from '../../../util/helpers';
import { PersonalStep } from './personal-step';
import { PaymentStep } from './payment-step';
import Button from '../../button';
import { track, TrackingType } from '../../../tracking/ga';

export type PaymentInputValues = {
  name: string;
  zipCode: string;
  city: string;
  country: string;
  terms: boolean;
  email: string;
  creditCard: string;
};

type Props = {
  onSubmit: (args: {
    values: PaymentInputValues;
    cardElement: StripeCardElement;
    stripe: Stripe | null;
  }) => void;
  isCapturingPayment: boolean;
  paymentCaptureError?: StripeError;
};

const PaymentForm: React.FC<Props> = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [step, setStep] = useState<'personal' | 'payment'>('personal');
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    getValues,
    setError,
    clearErrors,
  } = useForm<PaymentInputValues>(
    isDevelopment
      ? {
          defaultValues: {
            city: 'John town',
            name: 'John doe',
            zipCode: '0000',
            country: 'DK',
            terms: true,
            email: 'john.doe@cactusglobal.com',
          },
        }
      : {},
  );

  // read errors from payment capturing and attach them to the credit card field
  useEffect(() => {
    if (!props.paymentCaptureError) {
      clearErrors('creditCard');
      return;
    }
    setError('creditCard', { type: 'capture', message: props.paymentCaptureError.message });
  }, [props.paymentCaptureError, setError, clearErrors]);

  const onSubmit: SubmitHandler<PaymentInputValues> = async (values) => {
    if (step === 'personal') {
      return setStep('payment');
    }
    const cardElement = elements?.getElement(CardElement);
    if (!cardElement) {
      throw new Error('no stripe card element');
    }
    props.onSubmit({ values, cardElement, stripe });
  };

  const onError = (errors: DeepMap<PaymentInputValues, FieldError>) => {
    (Object.keys(errors) as Array<keyof typeof errors>).forEach((key) => {
      const error = errors[key];
      track(
        'event',
        TrackingType.Category.paymentPage,
        TrackingType.Actions.error,
        `<${error?.message}>`,
      );
    });
  };

  // default view on form
  let content = <PersonalStep register={register} errors={errors} />;

  // view after personal step
  if (step === 'payment') {
    // eslint-disable-next-line no-control-regex

    content = (
      <PaymentStep
        goBack={() => setStep('personal')}
        isSubmitting={isSubmitting}
        isCapturingPayment={props.isCapturingPayment}
        getValues={getValues}
        register={register}
        errors={errors}
        control={control}
      />
    );
  }
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>{content}</Form>
      {isDevelopment && <DevTool control={control} placement="top-left" />}
    </>
  );
};

export default PaymentForm;

const Form = styled.form`
  display: grid;
  height: 100%;
  width: 100%;
  row-gap: 1rem;
  column-gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, auto);
  align-items: center;
`;

export const LinkButton = styled.button<{ underline?: boolean }>`
  border: none;
  background: none;
  font-size: inherit;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  padding: 0;
  text-align: end;
`;

export const CtaButton = styled(Button)`
  grid-column: 1/-1;
`;
