import styled from 'styled-components';
import Checkbox from '../../fields/checkbox';
import Input from '../../fields/input';
import Select from '../../fields/select';

import { DeepMap, FieldError, UseFormRegister } from 'react-hook-form';
import { CtaButton, LinkButton, PaymentInputValues } from './payment-form';
import { useModal } from '../../../hooks/use-modal';
import TocModal from '../toc-modal/toc-modal';
import { COUNTRIES } from '../../../util/countries';
import { useEffect } from 'react';
import { track, TrackingType } from '../../../tracking/ga';

const getCountries = () => Object.entries(COUNTRIES).map(([value, label]) => ({ value, label }));

type Props = {
  register: UseFormRegister<PaymentInputValues>;
  errors: DeepMap<PaymentInputValues, FieldError>;
  isCapturingPayment?: boolean;
};

export const PersonalStep: React.FC<Props> = (props) => {
  const { openModal, Modal } = useModal();
  useEffect(() => {
    track('event', TrackingType.Category.paymentPage, TrackingType.Actions.personalStep);
  }, []);
  return (
    <>
      <NameInput
        {...props.register('name', { required: 'Please enter your name' })}
        errors={props.errors}
        label="Your name"
      />
      <ZipCodeInput
        {...props.register('zipCode', { required: 'Please enter your zip' })}
        errors={props.errors}
        label="Zip code"
      />
      <CityInput
        {...props.register('city', { required: 'Please enter your city' })}
        errors={props.errors}
        label="City"
      />
      <CountrySelect
        {...props.register('country', { required: 'Please enter your country' })}
        errors={props.errors}
        options={getCountries()}
        label="Country"
      />
      <TermsCheckbox
        {...props.register('terms', {
          required: 'Please read and accept the terms of condition',
        })}
        errors={props.errors}
        label={
          <>
            I accept the{' '}
            <LinkButton underline type="button" onClick={openModal}>
              terms and conditions
            </LinkButton>
          </>
        }
      />
      <CtaButton
        onClick={() =>
          track(
            'event',
            TrackingType.Category.paymentPage,
            TrackingType.Actions.click,
            TrackingType.Labels.paymentIntent,
          )
        }
        variant="solid"
        color="primary"
      >
        Continue
      </CtaButton>
      <Modal>
        <TocModal />
      </Modal>
    </>
  );
};

const NameInput = styled(Input)`
  grid-column: 1/-1;
`;
const ZipCodeInput = styled(Input)`
  grid-column: 1/3;
`;
const CityInput = styled(Input)`
  grid-column: 3/-1;
`;
const CountrySelect = styled(Select)`
  grid-column: 1/-1;
`;
const TermsCheckbox = styled(Checkbox)`
  grid-column: 1/-1;
`;
